export default function initFilterButtons() {
    document.querySelectorAll('.filter-buttons form').forEach((form) => {
        const options = form.querySelectorAll('label');
        const show_more_btn = form.querySelector('.show-more-btn');

        if (show_more_btn) {
            show_more_btn.onclick = (event) => {
                event.preventDefault();
                event.stopPropagation();

                options.forEach(option => {
                    option.closest('li').classList.remove('hidden');
                });

                show_more_btn.remove();
            };
        }

        function toggle(option) {
            const button = option.querySelector('.btn');
            const input = option.querySelector('input[type="checkbox"]');
            const remove_btn = option.querySelector('.remove');

            if (input.checked) {
                button.classList.remove('active');
                remove_btn.classList.remove('visible');
                input.removeAttribute('checked');
                option.setAttribute('aria-checked', 'false');
            } else {
                button.classList.add('active');
                remove_btn.classList.add('visible');
                input.setAttribute('checked', 'checked');
                option.setAttribute('aria-checked', 'true');
            }
        }

        options.forEach(option => {
            option.onclick = (event) => {
                event.preventDefault();
                event.stopPropagation();
                toggle(option);
                form.submit();
            };

            option.onkeyup = (event) => {
                event.preventDefault();
                event.stopPropagation();
                if (event.key === 'Enter' || event.key === ' ') {
                    toggle(option);
                    form.submit();
                }
            };
        });
    });
}
